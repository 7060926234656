body {
    margin: 0;
    font-family: 'Graphik Compact', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'Graphik Compact';
    src: url('./assets/fonts/Graphik Compact Family/GraphikCompact-Medium-Trial.otf');
}

/* @font-face {
    font-family: 'Graphik Compact';
    src: url('./assets/fonts/Graphik Compact Family/GraphikCompact-Light-Trial.otf');
    font-weight: 400;
} */

@font-face {
    font-family: 'Graphik Compact';
    src: url('./assets/fonts/Graphik Compact Family/GraphikCompact-Bold-Trial.otf');
    font-weight: bold;
}

@font-face {
    font-family: 'Graphik Compact';
    src: url('./assets/fonts/Graphik Compact Family/GraphikCompact-MediumItalic-Trial.otf');
    font-style: italic;
}

@font-face {
    font-family: 'Graphik Compact';
    src: url('./assets/fonts/Graphik Compact Family/GraphikCompact-BoldItalic-Trial.otf');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik Compact';
    src: url('./assets/fonts/Graphik Compact Family/GraphikCompact-Black-Trial.otf');
    font-weight: 900;
}
